<template>
  <v-main>
    <v-container>
      <div id="blog-home">
        <v-divider class="mt-n10"></v-divider>
        <h1 class="text-center">-{{ page_title }}-</h1>
        <v-divider class="mb-6"></v-divider>
        <v-row>
          <v-col v-for="n in 8" :key="n" cols="6">
            <v-card>
              <div
                class="card-container"
                @click="
                  path = 'Blog/ejemplo';
                  navigation();
                "
              >
                <v-img
                  class="card-img"
                  src="https://cdn.vuetifyjs.com/images/cards/cooking.png"
                ></v-img>
                <v-card-title class="card-title">Titulo del blog</v-card-title>
                <v-card-text class="card-text">
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nisi
                  officia amet assumenda inventore impedit, rerum voluptate quia
                  quae deleniti accusamus? Molestias sit nesciunt ut ab nam
                  deserunt, repudiandae atque dignissimos.
                </v-card-text>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-container>
    <div class="text-center">
      <v-pagination v-model="page" :length="6"></v-pagination>
    </div>
  </v-main>
</template>

<script>
export default {
  name: "Blog",

  data() {
    return {
      page_title: "Blog",
      posts: [],
      page: 1,
    };
  },
  methods: {
    navigation() {
      /* Changes the path dynamically */
      window.location = this.path;
    },
  },
};
</script>
